@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .navEl {
        @apply flex flex-row gap-1 relative h-full justify-center items-center text-lg max-xl:flex-col max-xl:w-full;
    }
    .navSpan {
        @apply flex flex-row gap-2;
    }
    .dropDownEl {
        @apply p-4 text-black hover:bg-green hover:text-white duration-150 max-xl:px-16 max-sm:px-8;
    }
    .carouselSliderBtn {
        @apply block absolute bottom-1/2 translate-y-1/2 p-4 mx-4 rounded-full  hover:bg-black/50 duration-100;
    }
    
   

}

::-webkit-scrollbar {
    width: 8px; 
}

::-webkit-scrollbar-track {
    background: transparent;  
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); 
    border-radius: 100vw;
}


h1 {
    font-size: 56px;
    font-weight: 800;
    line-height: 1.2;
}
h2 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
}
h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.5;
}


p {
    font-size: 20px;
    line-height: 32px; 
}

a {
    color:#0BBBEF;
}

.reactMarkdown > ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 32px;
    list-style-type: disc;
    
}
.reactMarkdown ol {
    list-style-type: decimal;
    padding-left: 32px;
}



@media only screen and (max-width: 1536px) {
    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 36px;
    }
    h3 {
        font-size: 24px;
    }
    
    p {
        font-size: 18px;
        line-height: 30px; 
    }
}

@media only screen and (max-width: 1280px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 32px;
    }
    h3 {
        font-size: 22px;
    }
    p {
        font-size: 16px;
        line-height: 28px; 
    }
}

@media only screen and (max-width: 640px) {
    h1 {
        font-size: 28px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    p {
        font-size: 16px;
        line-height: 28px; /* 1.75 rem assuming base font size of 16px */
    }
}



@font-face {
    font-family: 'Degular-Regular';
    src: url('./assets/fonts/Degular-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

html * {
    overscroll-behavior: none;
    font-family: 'Degular-Regular', sans-serif;
    letter-spacing: 0.05em;
}
.contentful-content ul {
	list-style-type: disc;
	margin-left: 20px; 
}

.contentful-content ol {
	list-style-type: decimal;
	margin-left: 20px;
}

.contentful-content ul li,
.contentful-content ol li {
	margin-bottom: 5px; 
}
